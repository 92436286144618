var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "auth-wrapper auth-v1 px-2" }, [
    _c(
      "div",
      { staticClass: "auth-inner py-2" },
      [
        _c(
          "b-card",
          { staticClass: "mb-0" },
          [
            _c(
              "b-link",
              { staticClass: "brand-logo" },
              [
                _c("vuexy-logo"),
                _c("h2", { staticClass: "brand-text text-primary ml-1" }, [
                  _vm._v("Seducar"),
                ]),
              ],
              1
            ),
            _c("b-card-title", { staticClass: "mb-1" }, [
              _vm._v(" Reset Password 🔒 "),
            ]),
            _c("b-card-text", { staticClass: "mb-2" }, [
              _vm._v(
                " Your new password must be different from previously used passwords "
              ),
            ]),
            _c(
              "b-form",
              {
                staticClass: "auth-reset-password-form mt-2",
                attrs: { method: "POST" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.validationForm.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: "New Password",
                      "label-for": "reset-password-new",
                    },
                  },
                  [
                    _c(
                      "b-input-group",
                      { staticClass: "input-group-merge" },
                      [
                        _c("b-form-input", {
                          staticClass: "form-control-merge",
                          attrs: {
                            id: "reset-password-new",
                            type: _vm.password1FieldType,
                            name: "reset-password-new",
                            placeholder: "············",
                          },
                          model: {
                            value: _vm.password,
                            callback: function ($$v) {
                              _vm.password = $$v
                            },
                            expression: "password",
                          },
                        }),
                        _c(
                          "b-input-group-append",
                          { attrs: { "is-text": "" } },
                          [
                            _c("feather-icon", {
                              staticClass: "cursor-pointer",
                              attrs: { icon: _vm.password1ToggleIcon },
                              on: { click: _vm.togglePassword1Visibility },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      "label-for": "reset-password-confirm",
                      label: "Confirm Password",
                    },
                  },
                  [
                    _c(
                      "b-input-group",
                      {
                        staticClass: "input-group-merge",
                        class: _vm.errors.length > 0 ? "is-invalid" : null,
                      },
                      [
                        _c("b-form-input", {
                          staticClass: "form-control-merge",
                          attrs: {
                            id: "reset-password-confirm",
                            type: _vm.password2FieldType,
                            state: _vm.errors.length > 0 ? false : null,
                            name: "reset-password-confirm",
                            placeholder: "············",
                          },
                          model: {
                            value: _vm.cPassword,
                            callback: function ($$v) {
                              _vm.cPassword = $$v
                            },
                            expression: "cPassword",
                          },
                        }),
                        _c(
                          "b-input-group-append",
                          { attrs: { "is-text": "" } },
                          [
                            _c("feather-icon", {
                              staticClass: "cursor-pointer",
                              attrs: { icon: _vm.password2ToggleIcon },
                              on: { click: _vm.togglePassword2Visibility },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-button",
                  { attrs: { block: "", type: "submit", variant: "primary" } },
                  [_vm._v(" Set New Password ")]
                ),
              ],
              1
            ),
            _c(
              "p",
              { staticClass: "text-center mt-2" },
              [
                _c(
                  "b-link",
                  { attrs: { to: { name: "auth-login-v1" } } },
                  [
                    _c("feather-icon", { attrs: { icon: "ChevronLeftIcon" } }),
                    _vm._v(" Back to login "),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }